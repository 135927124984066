import React from "react";
// import "../Vitals.css";
import StressChart from "./StressChart";
import StressChartV1 from "./StressChartV1";
export default function Vitals() {
  return (
    <>
    
      <div className="flex md:flex-col flex-row md:space-3">
        <div className="flex flex-row flex-wrap space-y-2 md:space-x-1 mx-auto">
          <div
            href="#"
            class="md:mt-2 ml-1 md:ml-0 max-w-sm p-3 md:w-52 w-44 shadow-blue-300 bg-white border border-gray-200 rounded-lg shadow-lg flex flex-col"
          >
            <div className="text-blue-400 mx-auto font-bold">Heart Rate</div>
            <div>
              <input id="hr_index" hidden></input>
            </div>
            <input
              type="text"
              readOnly
              id="heart_rate"
              className="mx-auto w-10 font-black text-lg border-none hover:border-none "
            ></input>

            <div className="mx-auto text-blue-500">+/- 5 b.p.m</div>
          </div>
          <div
            href="#"
            class="max-w-sm p-3 md:w-52 w-44 shadow-blue-300 bg-white border border-gray-200 rounded-lg shadow-lg flex flex-col"
          >
            <div className="text-blue-400 mx-auto font-bold">
              Breathing Rate
            </div>
            <div>
              <input id="br_index" hidden></input>
            </div>
            <input
              type="text"
              readOnly
              id="resp_rate"
              className="mx-auto w-10 font-black text-lg border-none hover:border-none "
            ></input>

            <div className="mx-auto text-blue-500">+/- 5 b.p.m</div>
          </div>
          <div
            href="#"
            class="max-w-sm p-3 md:w-52 w-44 shadow-blue-300 bg-white border border-gray-200 rounded-lg shadow-lg flex flex-col"
          >
            <div className="text-blue-400 mx-auto font-bold">Spo2 Rate</div>
            <div>
              <input id="spo2_index" hidden></input>
            </div>
            <input
              type="text"
              readOnly
              id="spo2"
              className="mx-auto w-10 font-black text-lg border-none hover:border-none "
            ></input>

            <div className="mx-auto text-blue-500">+/- 2 %</div>
          </div>
        </div>
        {/* end */}
        <div className="flex flex-row flex-wrap space-y-2 space-x-1 mx-auto">
          <div
            href="#"
            class="md:mt-2 max-w-sm p-3 md:w-52 w-44 shadow-blue-300 bg-white border border-gray-200 rounded-lg shadow-lg flex flex-col"
          >
            <div className="text-blue-400 mx-auto font-bold">
              Blood Pressure
            </div>
            <div>
              <input id="bp_index" hidden></input>
            </div>
            <input
              type="text"
              readOnly
              id="blood_pressure"
              className="mx-auto w-16 font-black text-lg border-none hover:border-none "
            ></input>

            <div className="mx-auto text-blue-500">+/- 15 mmHg</div>
          </div>
          <div
            href="#"
            class="max-w-sm p-3 md:w-52 w-44 shadow-blue-300 bg-white border border-gray-200 rounded-lg shadow-lg flex flex-col"
          >
            <div className="text-blue-400 mx-auto font-bold">Blood Sugar</div>
            <div>
              <input id="bs_index" hidden></input>
            </div>
            <input
              type="text"
              readOnly
              id="bs_rate"
              className="mx-auto w-16 font-black text-lg border-none hover:border-none "
            ></input>

            <div className="mx-auto text-blue-500">+/- 1 mmol/L</div>
          </div>
          <div
            href="#"
            class="max-w-sm p-3 md:w-52 w-44 shadow-blue-300 bg-white border border-gray-200 rounded-lg shadow-lg flex flex-col"
          >
            <div className="text-blue-400 mx-auto font-bold">
              Blood Cholesterol
            </div>
            <div>
              <input id="bc_index" hidden></input>
            </div>
            <input
              type="text"
              readOnly
              id="chol_rate"
              className="mx-auto w-16 font-black text-lg border-none hover:border-none "
            ></input>

            <div className="mx-auto text-blue-500">+/- 18 mg/dL</div>
          </div>
        </div>

        <input hidden id="diag_value"></input>
        <input hidden id="diagnosis"></input>
        <input hidden id="check_hr"></input>
        <input hidden id="check_br"></input>
        <input hidden id="check_spo2"></input>
        <input hidden id="check_bp"></input>
        <input hidden id="check_bs"></input>
        <input hidden id="check_bc"></input>
        <br></br>
      </div>
      <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
      <StressChart />
    </>
    // <div className='vitals__body'>
    //     <div className='scan__vitalBox'>
    //         <div className='scan__Box'>
    //             <div className='scanLabel__box'>
    //                 <label htmlFor='heart_rate' className='scan__label' >Heart Rate</label>
    //             </div>
    //             <input  id='hr_index' hidden></input>
    //             <div className='scanInput__box'>
    //                 <input type="text" readOnly id="heart_rate" className='scan__input'></input>
    //             </div>
    //             <div className='plusmin__box'>
    //                 <p><span className='plusMin'>+/- 5 b.p.m</span></p>
    //             </div>
    //         </div>
    //         <div className='scan__Box'>
    //             <div className='scanLabel__box'>
    //                 <label htmlFor='resp_rate' className='scan__label'>Breathing Rate</label>
    //             </div>
    //             <input  id='br_index' hidden></input>
    //             <div className='scanInput__box'>
    //                 <input type="text" readOnly id="resp_rate" className='scan__input'  ></input>
    //             </div>
    //             <div className='plusmin__box'>
    //                 <p><span className='plusMin'>+/- 5 b.p.m</span></p>
    //             </div>
    //         </div>
    //         <div className='scan__Box'>
    //             <div className='scanLabel__box'>
    //                 <label htmlFor='spo2' className='scan__label'>Spo2 Rate</label>
    //             </div>
    //             <input  id='spo2_index' hidden></input>
    //             <div className='scanInput__box'>
    //                 <input type="text" readOnly id="spo2" className='scan__input' ></input>
    //             </div>
    //             <div className='plusmin__box'>
    //                 <p><span className='plusMin'>+/- 2 %</span></p>
    //             </div>
    //         </div>
    //     </div>
    //     <div className='scan__vitalBox'>
    //         <div className='scan__Box'>
    //             <div className='scanLabel__box'>
    //                 <label htmlFor='blood_pressure' className='scan__label'>Blood Pressure</label>
    //             </div>
    //             <input  id='bp_index' hidden></input>
    //             <div className='scanInput__box'>
    //                 <input type="text" readOnly id="blood_pressure" className='scan__input' ></input>
    //             </div>
    //             <div className='plusmin__box'>
    //                 <p><span className='plusMin'>+/- 15 mmHg</span></p>
    //             </div>
    //         </div>
    //         <div className='scan__Box'>
    //             <div className='scanLabel__box'>
    //                 <label htmlFor='bs_rate' className='scan__label'>Blood Sugar</label>
    //             </div>
    //             <input  id='bs_index' hidden></input>
    //             <div className='scanInput__box'>
    //                 <input type="text" readOnly id="bs_rate" className='scan__input' ></input>
    //             </div>
    //             <div className='plusmin__box'>
    //                 <p><span className='plusMin'>+/- 1 mmol/L</span></p>
    //             </div>
    //         </div>
    //         <div className='scan__Box'>
    //             <div className='scanLabel__box'>
    //                 <label htmlFor='chol_rate' className='scan__label'>Blood Cholesterol</label>
    //             </div>
    //             <input  id='bc_index' hidden></input>
    //             <div className='scanInput__box'>
    //                 <input type="text" readOnly id="chol_rate" className='scan__input' ></input>
    //             </div>
    //             <div className='plusmin__box'>
    //                 <p><span className='plusMin'>+/- 18 mg/dL</span></p>
    //             </div>
    //         </div>
    //     </div>
    //     <div className='divider__container'>
    //         <hr className='divider__style'></hr>
    //     </div>
    //     <div>
    //         <input hidden id='diag_value' ></input>
    //         <input hidden id='diagnosis'></input>
    //         <input hidden id='check_hr'></input>
    //         <input hidden id='check_br'></input>
    //         <input hidden id='check_spo2'></input>
    //         <input hidden id='check_bp'></input>
    //         <input hidden id='check_bs'></input>
    //         <input hidden id='check_bc'></input>
    //     </div>
    //     <StressChart />
    // </div>
  );
}
