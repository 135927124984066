import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PayPal from "./PayPal";
import SubscriptionModal from "./SubscriptionModal";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import axios from "axios";
import url from "./url";

import img from "../assets/images/Vital Signs 02.jpg";

let config = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("token"),
  },
};

const Subscription = () => {
  const [unitcost, setUnitCost] = useState(1);
  const [total, setTotal] = useState(1);
  const [items, setItems] = useState(1);
  const [spin, setSpin] = useState(false);
  const navigate = useNavigate();

  // const [, updateState] = React.useState();
  // const forceUpdate = React.useCallback(() => updateState({}), []);
  const updateItems = (action) => {
    if (action == "increment") {
      setItems(items + 1);
    } else {
      if (items > 1) {
        setItems(items - 1);
      }
    }
  };

  useEffect(() => {
    // forceUpdate();
    setTotal(unitcost * items);
    let tot = unitcost * items;

    sessionStorage.setItem("total", tot.toString());
  }, [items]);

  const updateScan = () => {
    setSpin(true);
    axios
      .post(
        url + "sdk/subscriptions/create",
        {
          scans: items,
          currency: "USD",
          amount: total,
        },
        config
      )
      .then((res) => {
        console.log(res);
        // alert("It went successfully");
        navigate("/scan");
      })
      .catch((r) => {
        console.log(r);
      })
      .then((r) => {
        setSpin(false);
      });
    // alert("It went successfully");
  };

  return (
    <PayPalScriptProvider
      options={{
        "client-id":
          "ARDyLYhfZjvDRKzlx-2L0-EB5vuIrdyLFkJCQ8GElr-ny62MwqDpu69hftfSMmlgM8YMTKpXkxiDeO90&enable-funding=venmo&currency=USD",
      }}
    >
      {/* <button onClick={() => updateScan()}>Update</button> */}

      <div className="error__value text-red-500">
        {spin ? (
          <>
            <div role="status">
              <svg
                aria-hidden="true"
                class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
          </>
        ) : null}{" "}
      </div>

      <div class="container mx-auto mt-10 flex flex-col">
        {/* <PayPalButtons style={{ layout: "horizontal" }} /> */}

        {/* <div id="smart-button-container">
        <div>
          <div id="paypal-button-container"></div>
        </div>
      </div> */}

        {/* <PayPal /> */}
        <div className="border-2 p-4 rounded-2xl shadow-xl flex flex-col">

        
          Our wellness tracking platform can help you improve your overall
          wellness, and to access all of our features, we require a small
          payment of $1 per scan. This will allow you to add and track as many
          scans as you need. Please add the desired number of scans to your cart
          and proceed to checkout. Thank you!
        </div>

        <div class="flex shadow-md my-10 flex-col md:flex-row">
          <div class="w-full bg-white md:px-10 md:py-10">
            <div class="flex justify-between border-b pb-8">
              <h1 class="font-semibold text-2xl">Scans Subscription</h1>
              <h2 class="font-semibold text-2xl">{items} Items</h2>
            </div>
            <div class="flex mt-10 mb-5">
              <h3 class="font-semibold text-gray-600 text-xs uppercase md:w-2/5 w-1/2">
                Product Details
              </h3>
              <h3 class="font-semibold text-center text-gray-600 text-xs uppercase text-center">
                Quantity
              </h3>
            </div>
            <div class="flex items-center hover:bg-gray-100 md:px-6 md:py-5">
              <div class="flex w-2/5">
                <div class="md:w-28">
                  <img class="h-28" src={img} alt="" />
                </div>
                <div class="flex flex-col justify-between ml-4 flex-grow">
                  <span class="font-bold text-sm">Wellness Reader</span>
                  {/* <span class="text-red-500 text-xs">Docsun AI</span> */}
                </div>
              </div>
              <div class="flex justify-center md:w-1/5">
                {/* <svg class="fill-current text-gray-600 w-3" viewBox="0 0 448 512">
                <path d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" />
              </svg> */}
                <button
                  onClick={() => updateItems("decrement")}
                  type="button"
                  class="font-black text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300  rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                >
                  <div className="">-</div>
                </button>

                <input
                  class="mx-2 border text-center w-8"
                  type="text"
                  value={items}
                />
                <button
                  onClick={() => updateItems("increment")}
                  type="button"
                  class=" font-black text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300  rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                >
                  +
                </button>

                {/* <svg class="fill-current text-gray-600 w-3 border-2 p-1" viewBox="0 0 448 512">
                <path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" />
              </svg> */}
              </div>
            </div>

            <Link
              to="/scan"
              class="md:flex font-semibold text-indigo-600 text-sm mt-10 hidden md:visible"
            >
              <svg
                class="fill-current mr-2 text-indigo-600 w-4"
                viewBox="0 0 448 512"
              >
                <path d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z" />
              </svg>
              Back to Scan
            </Link>
          </div>

          <div id="summary" class="md:w-1/4  py-10">
            <h1 class="font-semibold text-2xl border-b pb-8">Order Summary</h1>
            <div class="flex justify-between mt-10 mb-5">
              <span class="font-semibold text-sm uppercase">Scans </span>
              <span class="font-semibold text-sm">
                <span className="font-black text-blue-500 mr-8">{items}</span>
              </span>
            </div>
            <div class="flex justify-between mt-10 mb-5">
              <span class="font-semibold text-sm uppercase">Unit Price </span>
              <span class="font-semibold text-sm">
                <span className="font-black text-blue-500 mr-8">
                  ${unitcost}
                </span>
              </span>
            </div>

            <div class="border-t mt-8">
              <div class="flex font-semibold justify-between py-6 text-sm uppercase">
                <span>Total cost</span>
                <span className="mr-8 font-black text-blue-500">${total}</span>
              </div>
              {/* <button class="bg-indigo-500 font-semibold hover:bg-indigo-600 py-3 text-sm text-white uppercase w-full">
              Checkout

            </button> */}
              {/* <div id="smart-button-container">
              <div>
                <div id="paypal-button-container"></div>
              </div>
            </div> */}

              <PayPalButtons
                createOrder={(data, actions) => {
                  return actions.order.create({
                    purchase_units: [
                      {
                        amount: {
                          // value: returnAmount(),
                          value: parseInt(sessionStorage.getItem("total")),
                        },
                      },
                    ],
                  });
                }}
                onApprove={(data, actions) => {
                  return actions.order.capture().then((details) => {
                    const name = details.payer.name.given_name;
                    alert(`Transaction completed by ${name}`);
                    updateScan();
                  });
                }}
                onError={(e) => {
                  console.log(e);
                  alert(e);
                }}
                onCancel={(data) => {
                  console.log(data);
                  alert(data);
                }}
              />
            </div>
          </div>
          <Link
            to="/scan"
            class="flex font-semibold text-indigo-600 text-sm m-5 md:hidden visible"
          >
            <svg
              class="fill-current mr-2 text-indigo-600 w-4"
              viewBox="0 0 448 512"
            >
              <path d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z" />
            </svg>
            Back to Scan
          </Link>
        </div>
      </div>
    </PayPalScriptProvider>
  );
};

export default Subscription;
