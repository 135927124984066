import { React, useRef, useState } from "react";
import "../Scan.css";
import inst_video from "../video/inst_video.webm";
import Vitals from "./Vitals";
import VitalsV1 from "./VitalsV1";

function ScanV1() {
  const [hideVideo, sethideVideo] = useState(false);
  const [hideSkip, sethideSkip] = useState(false);
  const vidRef = useRef(null);
  const [fpsStarted, setStartedFps] = useState(false);
  const handleStopVideo = () => {
    vidRef.current.pause();
  };
  const handleStartedFps = () => {
    setStartedFps(true);
  };
  return (
    <div className="flex w-full">
      <div className="mx-auto">
        <h2 id="checker">Modules Loading</h2>
        <div>
          <p className="version">version-3.0.221205</p>
          <p className="fps">
            FPS : <span id="fps_msg"></span>
          </p>
        </div>
        <div className="webcam__container">
          <div className="skip__container">
            <div id="skip__indication" hidden onClick={handleStartedFps}></div>
            {fpsStarted ? (
              <div>
                {hideSkip ? (
                  <button
                    id="skip__btn"
                    className=""
                    onClick={() => {
                      sethideVideo(true);
                      sethideSkip(true);
                      handleStopVideo();
                    }}
                    hidden={hideSkip}
                  >
                    Skip Video
                  </button>
                ) : (
                  <button
                    id="skip__btn"
                    className="skip__btn"
                    onClick={() => {
                      sethideVideo(true);
                      sethideSkip(true);
                      handleStopVideo();
                    }}
                    hidden={hideSkip}
                  >
                    Skip Video
                  </button>
                )}
              </div>
            ) : (
              <div hidden className="">
                {hideSkip ? (
                  <button
                    id="skip__btn"
                    className=""
                    onClick={() => {
                      sethideVideo(true);
                      sethideSkip(true);
                      handleStopVideo();
                    }}
                    hidden={hideSkip}
                  >
                    Skip Video
                  </button>
                ) : (
                  <button
                    id="skip__btn"
                    className="skip__btn"
                    onClick={() => {
                      sethideVideo(true);
                      sethideSkip(true);
                      handleStopVideo();
                    }}
                    hidden={hideSkip}
                  >
                    Skip Video
                  </button>
                )}
              </div>
            )}
          </div>
          <div className="inst__container">
            <video
              ref={vidRef}
              loop
              muted
              autoPlay
              controls
              hidden={hideVideo}
              className="inst__video"
            >
              <source src={inst_video} type="video/webm"></source>
            </video>
          </div>
          <video hidden id="video" width="640" height="480"></video>

          <div className="webcam__video">
            <div className="progress__container">
              <div id="progress__value"></div>
            </div>
            <canvas id="canvas" width="640" height="480"></canvas>
          </div>
          <div className="instruction__box">
            <p>Please look directly into the camera during scanning</p>
          </div>
          <div className="button__container">
            <button id="start_stop_btn" value="1" className="start__button">
              Start Capture
            </button>
          </div>
        </div>
        <div className="info__container">
          <p className="info__message">
            Message : <span className="info__value" id="prog_dynamic"></span>
          </p>
        </div>
        <VitalsV1 />
      </div>
    </div>
  );
}

export default ScanV1;
