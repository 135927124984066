import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import url from "./url";

var authContext = createContext();
// let prodURL = "https://wai.docsun.health/api/clientron";
let prodURL = "https://fnb-test.api.docsun.health/api";
export function useAuth() {
  const login = async (uname, pass) => {
    let data = {
      email: uname,
      password: pass,
    };
    let response = await fetch(`${url}/sdk/auth/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });
    response = await response.json();
    // alert(response);
    if (response.success) {
      localStorage.setItem("status", true);
      localStorage.setItem("licence", response.licencekey);
      localStorage.setItem("token", response.accessToken);
      return {
        status: true,
        message: response.message,
        accessToken: response.result.accessToken,
        licencekey: response.result.licencekey,
        id: response.result.id,
      };
    } else {
      localStorage.setItem("status", false);
      return {
        status: false,
        message: "Wrong Username or Password",
        accessToken: "",
        licencekey: "",
        id: "",
      };
    }
  };
  return {
    login,
  };
}
export function AuthProvider({ children }) {
  var auth = useAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
export default function AuthConsumer() {
  return useContext(authContext);
}
