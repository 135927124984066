import axios from "axios";
import { React, useEffect, useRef, useState } from "react";
import "../Scan.css";
import inst_video from "../video/inst_video.webm";
import Logout from "./Logout";
import SubscriptionModal from "./SubscriptionModal";
import url from "./url";
import Vitals from "./Vitals";

function Scan() {
  let [isOpen, setIsOpen] = useState(true);
  const [hideVideo, sethideVideo] = useState(false);
  const [hideSkip, sethideSkip] = useState(false);
  const vidRef = useRef(null);
  const [fpsStarted, setStartedFps] = useState(false);
  const handleStopVideo = () => {
    vidRef.current.pause();
  };
  const handleStartedFps = () => {
    setStartedFps(true);
  };
  return (
    <div className="flex w-full">
      <SubscriptionModal />

      <div className="mx-auto version">
        <div className="flex flex-row justify-between mt-3">
          <div>
            <h2 id="checker" className="text-blue-500 text-2xl font-black">
              Modules Loading
            </h2>
          </div>
          <div>{/* <Logout /> */}</div>
        </div>
        <div>
          <p className="version">version-3.0.221205</p>
          <p className="fps">
            FPS : <span id="fps_msg"></span>
          </p>
        </div>
        <div className="webcam__container">
          <div className="skip__container">
            <div id="skip__indication" hidden onClick={handleStartedFps}></div>
            {fpsStarted ? (
              <div>
                {hideSkip ? (
                  <button
                    id="skip__btn"
                    className=""
                    onClick={() => {
                      sethideVideo(true);
                      sethideSkip(true);
                      handleStopVideo();
                    }}
                    hidden={hideSkip}
                  >
                    Skip Video
                  </button>
                ) : (
                  <button
                    id="skip__btn"
                    className="skip__btn"
                    onClick={() => {
                      sethideVideo(true);
                      sethideSkip(true);
                      handleStopVideo();
                    }}
                    hidden={hideSkip}
                  >
                    Skip Video
                  </button>
                )}
              </div>
            ) : (
              <div hidden className="">
                {hideSkip ? (
                  <button
                    id="skip__btn"
                    className=""
                    onClick={() => {
                      sethideVideo(true);
                      sethideSkip(true);
                      handleStopVideo();
                    }}
                    hidden={hideSkip}
                  >
                    Skip Video
                  </button>
                ) : (
                  <button
                    id="skip__btn"
                    className="skip__btn"
                    onClick={() => {
                      sethideVideo(true);
                      sethideSkip(true);
                      handleStopVideo();
                    }}
                    hidden={hideSkip}
                  >
                    Skip Video
                  </button>
                )}
              </div>
            )}
          </div>
          <div className="inst__container">
            <video
              ref={vidRef}
              loop
              muted
              autoPlay
              controls
              hidden={hideVideo}
              className="inst__video"
            >
              <source src={inst_video} type="video/webm"></source>
            </video>
          </div>
          <video hidden id="video" width="640" height="480"></video>

          <div className="webcam__video">
            <div className="progress__container w-80 md:w-[600px]">
              <div id="progress__value"></div>
            </div>
            <canvas
              id="canvas"
              className="aspect-video md:w-[640px] md:h-[480px] h-48 "
            ></canvas>
          </div>
          <div className="instruction__box">
            <p className="">
              Please look directly into the camera during scanning
            </p>
          </div>
          <div className="button__container">
            <button id="start_stop_btn" value="1" className="start__button">
              Start Capture
            </button>
          </div>
        </div>
        <div className="flex flex-col w-full">
          {/* <div className="instruction__box info__container md:hiddend my-3 w-full">
            <p className="mx-auto">
              {" "}
              <center>
                Please look directly into the camera during scanning{" "}
              </center>
            </p>
          </div> */}
          <div className="info__container my-14 md:my-0">
            <p className="info__message">
              Message : <span className="info__value" id="prog_dynamic"></span>
            </p>
          </div>
        </div>

        <Vitals />
      </div>
    </div>
  );
}

export default Scan;
