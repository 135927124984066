import React, { useRef, useState } from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  Tooltip,
  PieChart,
  Pie,
  Cell,
  Label,
  ResponsiveContainer,
} from "recharts";
// import "../Chart.css";

function StressChart() {
  const scoreTemplate = [{ name: "Cell", value: 100 }];
  const stressPieRef = useRef(null);
  const stressRadarRef = useRef(null);
  const stressRecomRef = useRef(null);

  const fatiguePieRef = useRef(null);
  const fatigueRadarRef = useRef(null);
  const fatigueRecomRef = useRef(null);

  const yinPieRef = useRef(null);
  const yinRadarRef = useRef(null);
  const yinRecomRef = useRef(null);

  const yangPieRef = useRef(null);
  const yangRadarRef = useRef(null);
  const yangRecomRef = useRef(null);

  const strokePieRef = useRef(null);
  const strokeRadarRef = useRef(null);
  const strokeRecomRef = useRef(null);

  const heiPieRef = useRef(null);
  const heiRadarRef = useRef(null);
  const heiRecomRef = useRef(null);

  const vitaPieRef = useRef(null);
  const vitaRadarRef = useRef(null);
  const vitaRecomRef = useRef(null);

  const sensePieRef = useRef(null);
  const senseRadarRef = useRef(null);
  const senseRecomRef = useRef(null);

  const sugarMetaPieRef = useRef(null);
  const sugarMetaRadarRef = useRef(null);
  const sugarMetaRecomRef = useRef(null);

  const cholMetaPieRef = useRef(null);
  const cholMetaRadarRef = useRef(null);
  const cholMetaRecomRef = useRef(null);

  const o2MetaPieRef = useRef(null);
  const o2MetaRadarRef = useRef(null);
  const o2MetaRecomRef = useRef(null);

  // background-image: linear-gradient(to right top, #61f28a, #54f194, #47ef9e, #39eea7, #2becb0, #00e8c0, #00e4ce, #00dfd8, #00d6e7, #00ccf2, #00c0f9, #37b3f9);
  const COLORS = [
    "#61f28a",
    "#47ef9e",
    "#2becb0",
    "#00e4ce",
    "#00d6e7",
    "#00c0f9",
  ];

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label} : ${payload[0].value}`}</p>
          {/* <p className="intro">{getIntroOfPage(label)}</p> */}
          {/* <p className="desc">High levels of Stress detected.</p> */}
        </div>
      );
    }

    return null;
  };
  const CustomLabel = ({ viewBox, value1, value2 }) => {
    // console.log(viewBox, "v");
    const { cx, cy } = viewBox;
    return (
      <svg
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
      >
        <text x={cx} y={cy} fill="#3d405c">
          <tspan
            x={cx}
            dy="0em"
            alignmentBaseline="middle"
            fontSize="30"
            fontWeight={600}
          >
            {value1}
          </tspan>
          <tspan x={cx} dy="1.8em" fontSize="18" fontWeight={500}>
            {value2}
          </tspan>
        </text>
      </svg>
    );
  };
  const CustomLabelTitle = ({ viewBox, value1 }) => {
    // console.log(viewBox, "v");
    const { cx, cy } = viewBox;
    return (
      <svg
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
      >
        <text x={cx} y={cy - 120} fill="#3d405c">
          <tspan
            x={cx}
            dy="0em"
            alignmentBaseline="middle"
            fontSize="20"
            fontWeight={600}
          >
            {value1}
          </tspan>
        </text>
      </svg>
    );
  };
  const [stresPieState, setStressPie] = useState({
    name: "",
    value: null,
    angle: 170,
  });
  var [stresRadarState, setStressRadar] = useState([
    { name: "Rapid Breathing", x: 0 },
    { name: "Hypertension", x: 0 },
    { name: "Hypotension", x: 0 },
    { name: "Quiet Breathing", x: 0 },
    { name: "Resting Pulse Rate", x: 0 },
    { name: "Rapid Pulse Rate", x: 0 },
  ]);
  var [stressRecomState, setStressRecom] = useState({
    title: "",
    sub: "",
    suggest: [],
  });
  const handleStressPieOnClick = () => {
    setHideChart(false);
    console.log("Stress Index value : ", stressPieRef.current.value);
    setStressPie({
      name: stressPieRef.current.value["name"],
      value: stressPieRef.current.value["value"],
      angle: stressPieRef.current.value["angle"],
    });
  };
  const handleStressRadarOnClick = () => {
    console.log("Stress Radar Index value : ", stressRadarRef.current.value);
    setStressRadar(stressRadarRef.current.value);
  };
  const handleStressRecomOnClick = () => {
    console.log("Stress Recom Index value : ", stressRecomRef.current.value);
    setStressRecom(stressRecomRef.current.value);
  };

  const [fatiguePieState, setFatiguePie] = useState({
    name: "",
    value: null,
    angle: 170,
  });
  var [fatigueRadarState, setFatigueRadar] = useState([
    { name: "Hypoxemia", x: 0 },
    { name: "Slow Palpitation", x: 0 },
    { name: "Rapid Palpitation", x: 0 },
  ]);
  var [fatigueRecomState, setFatigueRecom] = useState({
    title: "",
    sub: "",
    suggest: [],
  });
  const handleFatiguePieOnClick = () => {
    setHideChart(false);
    console.log("Fatigue Index value : ", fatiguePieRef.current.value);
    setFatiguePie({
      name: fatiguePieRef.current.value["name"],
      value: fatiguePieRef.current.value["value"],
      angle: fatiguePieRef.current.value["angle"],
    });
  };
  const handleFatigueRadarOnClick = () => {
    console.log("Fatigue Radar Index value : ", fatigueRadarRef.current.value);
    setFatigueRadar(fatigueRadarRef.current.value);
  };
  const handleFatigueRecomOnClick = () => {
    console.log("Fatigue Recom Index value : ", fatigueRecomRef.current.value);
    setFatigueRecom(fatigueRecomRef.current.value);
  };

  const [yinPieState, setYinPie] = useState({
    name: "",
    value: null,
    angle: 170,
  });
  var [yinRadarState, setYinRadar] = useState([
    { name: "After Sleep", x: 0 },
    { name: "High Blood Pressure", x: 0 },
    { name: "Low Blood Pressure", x: 0 },
    { name: "Before Sleep", x: 0 },
  ]);
  var [yinRecomState, setYinRecom] = useState({
    title: "",
    sub: "",
    suggest: [],
  });
  const handleYinPieOnClick = () => {
    setHideChart(false);
    console.log("Yin Index value : ", yinPieRef.current.value);
    setYinPie({
      name: yinPieRef.current.value["name"],
      value: yinPieRef.current.value["value"],
      angle: yinPieRef.current.value["angle"],
    });
  };
  const handleYinRadarOnClick = () => {
    console.log("Yin Radar Index value : ", yinRadarRef.current.value);
    setYinRadar(yinRadarRef.current.value);
  };
  const handleYinRecomOnClick = () => {
    console.log("Yin Recom Index value : ", yinRecomRef.current.value);
    setYinRecom(yinRecomRef.current.value);
  };

  const [yangPieState, setYangPie] = useState({
    name: "",
    value: null,
    angle: 170,
  });
  var [yangRadarState, setYangRadar] = useState([
    { name: "After Sleep", x: 0 },
    { name: "High Blood Pressure", x: 0 },
    { name: "Low Blood Pressure", x: 0 },
    { name: "Before Sleep", x: 0 },
  ]);
  var [yangRecomState, setYangRecom] = useState({
    title: "",
    sub: "",
    suggest: [],
  });
  const handleYangPieOnClick = () => {
    setHideChart(false);
    console.log("Yang Index value : ", yangPieRef.current.value);
    setYangPie({
      name: yangPieRef.current.value["name"],
      value: yangPieRef.current.value["value"],
      angle: yangPieRef.current.value["angle"],
    });
  };
  const handleYangRadarOnClick = () => {
    console.log("Yang Radar Index value : ", yangRadarRef.current.value);
    setYangRadar(yangRadarRef.current.value);
  };
  const handleYangRecomOnClick = () => {
    console.log("Yang Recom Index value : ", yangRecomRef.current.value);
    setYangRecom(yangRecomRef.current.value);
  };

  const [sensePieState, setSensePie] = useState({
    name: "",
    value: null,
    angle: 170,
  });
  var [senseRadarState, setSenseRadar] = useState([
    { name: "Morning", x: 0 },
    { name: "Noon", x: 0 },
    { name: "Night", x: 0 },
    { name: "Rapid Pulse Rate", x: 0 },
    { name: "Resting Pulse Rate", x: 0 },
  ]);
  var [senseRecomState, setSenseRecom] = useState({
    title: "",
    sub: "",
    suggest: [],
  });
  const handleSensePieOnClick = () => {
    setHideChart(false);
    console.log("Sense Index value : ", sensePieRef.current.value);
    setSensePie({
      name: sensePieRef.current.value["name"],
      value: sensePieRef.current.value["value"],
      angle: sensePieRef.current.value["angle"],
    });
  };
  const handleSenseRadarOnClick = () => {
    console.log("Sense Radar Index value : ", senseRadarRef.current.value);
    setSenseRadar(senseRadarRef.current.value);
  };
  const handleSenseRecomOnClick = () => {
    console.log("Sense Recom Index value : ", senseRecomRef.current.value);
    setSenseRecom(senseRecomRef.current.value);
  };

  const [sugarMetaPieState, setSugarMetaPie] = useState({
    name: "",
    value: null,
    angle: 170,
  });
  var [sugarMetaRadarState, setSugarMetaRadar] = useState([
    { name: "Morning", x: 0 },
    { name: "Noon", x: 0 },
    { name: "Night", x: 0 },
    { name: "Rapid Pulse Rate", x: 0 },
    { name: "Resting Pulse Rate", x: 0 },
  ]);
  var [sugarMetaRecomState, setSugarMetaRecom] = useState({
    title: "",
    sub: "",
    suggest: [],
  });
  const handleSugarMetaPieOnClick = () => {
    setHideChart(false);
    console.log("SugarMeta Index value : ", sugarMetaPieRef.current.value);
    setSugarMetaPie({
      name: sugarMetaPieRef.current.value["name"],
      value: sugarMetaPieRef.current.value["value"],
      angle: sugarMetaPieRef.current.value["angle"],
    });
  };
  const handleSugarMetaRadarOnClick = () => {
    console.log(
      "SugarMeta Radar Index value : ",
      sugarMetaRadarRef.current.value
    );
    setSugarMetaRadar(sugarMetaRadarRef.current.value);
  };
  const handleSugarMetaRecomOnClick = () => {
    console.log(
      "SugarMeta Recom Index value : ",
      sugarMetaRecomRef.current.value
    );
    setSugarMetaRecom(sugarMetaRecomRef.current.value);
  };

  const [cholMetaPieState, setCholMetaPie] = useState({
    name: "",
    value: null,
    angle: 170,
  });
  var [cholMetaRadarState, setCholMetaRadar] = useState([
    { name: "Morning", x: 0 },
    { name: "Noon", x: 0 },
    { name: "Night", x: 0 },
    { name: "High Cholesterol Level", x: 0 },
    { name: "Low Cholesterol Level", x: 0 },
  ]);
  var [cholMetaRecomState, setCholMetaRecom] = useState({
    title: "",
    sub: "",
    suggest: [],
  });
  const handleCholMetaPieOnClick = () => {
    setHideChart(false);
    console.log("CholMeta Index value : ", cholMetaPieRef.current.value);
    setCholMetaPie({
      name: cholMetaPieRef.current.value["name"],
      value: cholMetaPieRef.current.value["value"],
      angle: cholMetaPieRef.current.value["angle"],
    });
  };
  const handleCholMetaRadarOnClick = () => {
    console.log(
      "CholMeta Radar Index value : ",
      cholMetaRadarRef.current.value
    );
    setCholMetaRadar(cholMetaRadarRef.current.value);
  };
  const handleCholMetaRecomOnClick = () => {
    console.log(
      "CholMeta Recom Index value : ",
      cholMetaRecomRef.current.value
    );
    setCholMetaRecom(cholMetaRecomRef.current.value);
  };

  const [o2MetaPieState, setO2MetaPie] = useState({
    name: "",
    value: null,
    angle: 170,
  });
  var [o2MetaRadarState, setO2MetaRadar] = useState([
    { name: "Morning", x: 0 },
    { name: "Noon", x: 0 },
    { name: "Night", x: 0 },
    { name: "Low O2 Saturation", x: 0 },
    { name: "High O2 Saturation", x: 0 },
  ]);
  var [o2MetaRecomState, setO2MetaRecom] = useState({
    title: "",
    sub: "",
    suggest: [],
  });
  const handleO2MetaPieOnClick = () => {
    setHideChart(false);
    console.log("O2Meta Index value : ", o2MetaPieRef.current.value);
    setO2MetaPie({
      name: o2MetaPieRef.current.value["name"],
      value: o2MetaPieRef.current.value["value"],
      angle: o2MetaPieRef.current.value["angle"],
    });
  };
  const handleO2MetaRadarOnClick = () => {
    console.log("O2Meta Radar Index value : ", o2MetaRadarRef.current.value);
    setO2MetaRadar(o2MetaRadarRef.current.value);
  };
  const handleO2MetaRecomOnClick = () => {
    console.log("O2Meta Recom Index value : ", o2MetaRecomRef.current.value);
    setO2MetaRecom(o2MetaRecomRef.current.value);
  };

  const [strokePieState, setStrokePie] = useState({
    name: "",
    value: null,
    angle: 170,
  });
  var [strokeRadarState, setStrokeRadar] = useState([
    { name: "HyperTension", x: 0 },
    { name: "Hypotension", x: 0 },
    { name: "High Cholesterol", x: 0 },
    { name: "Low Cholesterol", x: 0 },
  ]);
  var [strokeRecomState, setStrokeRecom] = useState({
    title: "",
    sub: "",
    suggest: [],
  });
  const handleStrokePieOnClick = () => {
    setHideChart(false);
    console.log("Stroke Index value : ", strokePieRef.current.value);
    setStrokePie({
      name: strokePieRef.current.value["name"],
      value: strokePieRef.current.value["value"],
      angle: strokePieRef.current.value["angle"],
    });
  };
  const handleStrokeRadarOnClick = () => {
    console.log("Stroke Radar Index value : ", strokeRadarRef.current.value);
    setStrokeRadar(strokeRadarRef.current.value);
  };
  const handleStrokeRecomOnClick = () => {
    console.log("Stroke Recom Index value : ", strokeRecomRef.current.value);
    setStrokeRecom(strokeRecomRef.current.value);
  };

  const [heiPieState, setHEIPie] = useState({
    name: "",
    value: null,
    angle: 170,
  });
  var [heiRadarState, setHEIRadar] = useState([
    { name: "High Protein Diet", x: 0 },
    { name: "Low Protein Diet", x: 0 },
    { name: "High Fatty Diet", x: 0 },
    { name: "Low Fatty Diet", x: 0 },
    { name: "High Sugar Diet", x: 0 },
    { name: "Low Sugar Diet", x: 0 },
  ]);
  var [heiRecomState, setHEIRecom] = useState({
    title: "",
    sub: "",
    suggest: [],
  });
  const handleHEIPieOnClick = () => {
    setHideChart(false);
    console.log("HEI Index value : ", heiPieRef.current.value);
    setHEIPie({
      name: heiPieRef.current.value["name"],
      value: heiPieRef.current.value["value"],
      angle: heiPieRef.current.value["angle"],
    });
  };
  const handleHEIRadarOnClick = () => {
    console.log("HEI Radar Index value : ", heiRadarRef.current.value);
    setHEIRadar(heiRadarRef.current.value);
  };
  const handleHEIRecomOnClick = () => {
    console.log("HEI Recom Index value : ", heiRecomRef.current.value);
    setHEIRecom(heiRecomRef.current.value);
  };

  const [vitaPieState, setVitaPie] = useState({
    name: "",
    value: null,
    angle: 170,
  });
  var [vitaRadarState, setVitaRadar] = useState([
    { name: "Morning", x: 0 },
    { name: "Noon", x: 0 },
    { name: "Night", x: 0 },
    { name: "Hypertesion", x: 0 },
    { name: "Hypotension", x: 0 },
  ]);
  var [vitaRecomState, setVitaRecom] = useState({
    title: "",
    sub: "",
    suggest: [],
  });

  const handleVitaPieOnClick = () => {
    setHideChart(false);
    console.log("Vita Index value : ", vitaPieRef.current.value);
    setVitaPie({
      name: vitaPieRef.current.value["name"],
      value: vitaPieRef.current.value["value"],
      angle: vitaPieRef.current.value["angle"],
    });
  };
  const handleVitaRadarOnClick = () => {
    console.log("Vita Radar Index value : ", vitaRadarRef.current.value);
    setVitaRadar(vitaRadarRef.current.value);
  };
  const handleVitaRecomOnClick = () => {
    console.log("Vita Recom Index value : ", vitaRecomRef.current.value);
    setVitaRecom(vitaRecomRef.current.value);
  };
  const [hideChart, setHideChart] = useState(true);
  return (
    <div className="flex flex-col mx-auto">
      {hideChart ? (
        <div className="mx-auto" hidden>
          {/* Stress/Anxiety scoring */}
          <div className="chart__container flex flex-row w-full">
            hh
            <div className="chart__Box md:w-1/2">
              <div
                ref={stressPieRef}
                hidden
                id="stress_pie"
                onClick={handleStressPieOnClick}
              ></div>
              <PieChart width={300} height={300}>
                <Pie
                  data={scoreTemplate}
                  cx={150}
                  cy={190}
                  startAngle={180}
                  endAngle={stresPieState.angle}
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                >
                  <Label
                    position="center"
                    width={100}
                    content={<CustomLabelTitle value1={"Stress Score nnn"} />}
                  ></Label>
                  <Label
                    position="center"
                    width={100}
                    content={
                      <CustomLabel
                        value1={stresPieState.value}
                        value2={stresPieState.name}
                      />
                    }
                  ></Label>
                  {scoreTemplate.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <div className="radar__container">
              <div
                ref={stressRadarRef}
                hidden
                id="stress_radar"
                onClick={handleStressRadarOnClick}
              ></div>
              <RadarChart
                height={300}
                width={600}
                outerRadius="80%"
                data={stresRadarState}
              >
                <PolarGrid />
                <PolarAngleAxis dataKey="name" />
                <Radar
                  dataKey="x"
                  stroke="green"
                  fill="green"
                  fillOpacity={0.5}
                />
                <Tooltip content={<CustomTooltip />} />
              </RadarChart>
            </div>
            <div
              ref={stressRecomRef}
              hidden
              id="stress_recom"
              onClick={handleStressRecomOnClick}
            ></div>
          </div>
          <div className="recomm__container">
            <p className="score__word">
              <span>{stressRecomState.title}</span>
            </p>
            <br />
            <p className="word__definition">{stressRecomState.sub}</p>
            <ul className="word__ul">
              {stressRecomState.suggest.map((d) => (
                <li className="word__li">{d}</li>
              ))}
            </ul>
          </div>

          <div className="divider__container">
            <hr className="divider__style"></hr>
          </div>
          {/* Fatigue/Sleep scoring */}
          <div className="chart__container">
            <div className="chart__Box">
              <div
                ref={fatiguePieRef}
                hidden
                id="fatigue_pie"
                onClick={handleFatiguePieOnClick}
              ></div>
              <PieChart width={300} height={300}>
                <Pie
                  data={scoreTemplate}
                  cx={150}
                  cy={190}
                  startAngle={180}
                  endAngle={fatiguePieState.angle}
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                >
                  <Label
                    position="center"
                    width={100}
                    content={<CustomLabelTitle value1={"Fatigue Score"} />}
                  ></Label>
                  <Label
                    position="center"
                    width={100}
                    content={
                      <CustomLabel
                        value1={fatiguePieState.value}
                        value2={fatiguePieState.name}
                      />
                    }
                  ></Label>
                  {scoreTemplate.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[(index + 4) % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <div className="radar__container">
              <div
                ref={fatigueRadarRef}
                hidden
                id="fatigue_radar"
                onClick={handleFatigueRadarOnClick}
              ></div>
              <RadarChart
                height={300}
                width={600}
                outerRadius="80%"
                data={fatigueRadarState}
              >
                <PolarGrid />
                <PolarAngleAxis dataKey="name" />
                <Radar
                  dataKey="x"
                  stroke="green"
                  fill="green"
                  fillOpacity={0.5}
                />
                <Tooltip content={<CustomTooltip />} />
              </RadarChart>
            </div>
            <div
              ref={fatigueRecomRef}
              hidden
              id="fatigue_recom"
              onClick={handleFatigueRecomOnClick}
            ></div>
          </div>
          <div className="recomm__container">
            <p className="score__word">
              <span>{fatigueRecomState.title}</span>
            </p>
            <br />
            <p className="word__definition">{fatigueRecomState.sub}</p>
            <ul className="word__ul">
              {fatigueRecomState.suggest.map((d) => (
                <li className="word__li">{d}</li>
              ))}
            </ul>
          </div>
          <div className="divider__container">
            <hr className="divider__style"></hr>
          </div>
          {/* Yin/ scoring */}
          <div className="chart__container">
            <div className="chart__Box">
              <div
                ref={yinPieRef}
                hidden
                id="yin_pie"
                onClick={handleYinPieOnClick}
              ></div>
              <PieChart width={300} height={300}>
                <Pie
                  data={scoreTemplate}
                  cx={150}
                  cy={190}
                  startAngle={180}
                  endAngle={yinPieState.angle}
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                >
                  <Label
                    position="center"
                    width={100}
                    content={<CustomLabelTitle value1={"Yin Score"} />}
                  ></Label>
                  <Label
                    position="center"
                    width={100}
                    content={
                      <CustomLabel
                        value1={yinPieState.value}
                        value2={yinPieState.name}
                      />
                    }
                  ></Label>
                  {scoreTemplate.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[(index + 2) % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <div className="radar__container">
              <div
                ref={yinRadarRef}
                hidden
                id="yin_radar"
                onClick={handleYinRadarOnClick}
              ></div>
              <RadarChart
                height={300}
                width={600}
                outerRadius="80%"
                data={yinRadarState}
              >
                <PolarGrid />
                <PolarAngleAxis dataKey="name" />
                <Radar
                  dataKey="x"
                  stroke="green"
                  fill="green"
                  fillOpacity={0.5}
                />
                <Tooltip content={<CustomTooltip />} />
              </RadarChart>
            </div>
            <div
              ref={yinRecomRef}
              hidden
              id="yin_recom"
              onClick={handleYinRecomOnClick}
            ></div>
          </div>
          <div className="recomm__container">
            <p className="score__word">
              <span>{yinRecomState.title}</span>
            </p>
            <br />
            <p className="word__definition">{yinRecomState.sub}</p>
            <ul className="word__ul">
              {yinRecomState.suggest.map((d) => (
                <li className="word__li">{d}</li>
              ))}
            </ul>
          </div>
          <div className="divider__container">
            <hr className="divider__style"></hr>
          </div>
          {/* Yin/ scoring */}
          <div className="chart__container">
            <div className="chart__Box">
              <div
                ref={yangPieRef}
                hidden
                id="yang_pie"
                onClick={handleYangPieOnClick}
              ></div>
              <PieChart width={300} height={300}>
                <Pie
                  data={scoreTemplate}
                  cx={150}
                  cy={190}
                  startAngle={180}
                  endAngle={yangPieState.angle}
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                >
                  <Label
                    position="center"
                    width={100}
                    content={<CustomLabelTitle value1={"Yang Score"} />}
                  ></Label>
                  <Label
                    position="center"
                    width={100}
                    content={
                      <CustomLabel
                        value1={yangPieState.value}
                        value2={yangPieState.name}
                      />
                    }
                  ></Label>
                  {scoreTemplate.map((entry, index) => (
                    <Cell
                      key={`cell-${index + 4}`}
                      fill={COLORS[(index + 2) % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <div className="radar__container">
              <div
                ref={yangRadarRef}
                hidden
                id="yang_radar"
                onClick={handleYangRadarOnClick}
              ></div>
              <RadarChart
                height={300}
                width={600}
                outerRadius="80%"
                data={yangRadarState}
              >
                <PolarGrid />
                <PolarAngleAxis dataKey="name" />
                <Radar
                  dataKey="x"
                  stroke="green"
                  fill="green"
                  fillOpacity={0.5}
                />
                <Tooltip content={<CustomTooltip />} />
              </RadarChart>
            </div>
            <div
              ref={yangRecomRef}
              hidden
              id="yang_recom"
              onClick={handleYangRecomOnClick}
            ></div>
          </div>
          <div className="recomm__container">
            <p className="score__word">
              <span>{yangRecomState.title}</span>
            </p>
            <br />
            <p className="word__definition">{yangRecomState.sub}</p>
            <ul className="word__ul">
              {yangRecomState.suggest.map((d) => (
                <li className="word__li">{d}</li>
              ))}
            </ul>
          </div>
          <div className="divider__container">
            <hr className="divider__style"></hr>
          </div>
          {/* Sensitivity scoring */}
          <div className="chart__container">
            <div className="chart__Box">
              <div
                ref={sensePieRef}
                hidden
                id="sense_pie"
                onClick={handleSensePieOnClick}
              ></div>
              <PieChart width={300} height={300}>
                <Pie
                  data={scoreTemplate}
                  cx={150}
                  cy={190}
                  startAngle={180}
                  endAngle={sensePieState.angle}
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                >
                  <Label
                    position="center"
                    width={100}
                    content={<CustomLabelTitle value1={"Sensitivity Score"} />}
                  ></Label>
                  <Label
                    position="center"
                    width={100}
                    content={
                      <CustomLabel
                        value1={sensePieState.value}
                        value2={sensePieState.name}
                      />
                    }
                  ></Label>
                  {scoreTemplate.map((entry, index) => (
                    <Cell
                      key={`cell-${index + 4}`}
                      fill={COLORS[(index + 2) % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <div className="radar__container">
              <div
                ref={senseRadarRef}
                hidden
                id="sense_radar"
                onClick={handleSenseRadarOnClick}
              ></div>
              <RadarChart
                height={300}
                width={600}
                outerRadius="80%"
                data={senseRadarState}
              >
                <PolarGrid />
                <PolarAngleAxis dataKey="name" />
                <Radar
                  dataKey="x"
                  stroke="green"
                  fill="green"
                  fillOpacity={0.5}
                />
                <Tooltip content={<CustomTooltip />} />
              </RadarChart>
            </div>
            <div
              ref={senseRecomRef}
              hidden
              id="sense_recom"
              onClick={handleSenseRecomOnClick}
            ></div>
          </div>
          <div className="recomm__container">
            <p className="score__word">
              <span>{senseRecomState.title}</span>
            </p>
            <br />
            <p className="word__definition">{senseRecomState.sub}</p>
            <ul className="word__ul">
              {senseRecomState.suggest.map((d) => (
                <li className="word__li">{d}</li>
              ))}
            </ul>
          </div>
          <div className="divider__container">
            <hr className="divider__style"></hr>
          </div>
          {/* Sugar Metabolism scoring */}
          <div className="chart__container">
            <div className="chart__Box">
              <div
                ref={sugarMetaPieRef}
                hidden
                id="sugarMeta_pie"
                onClick={handleSugarMetaPieOnClick}
              ></div>
              <PieChart width={300} height={300}>
                <Pie
                  data={scoreTemplate}
                  cx={150}
                  cy={190}
                  startAngle={180}
                  endAngle={sugarMetaPieState.angle}
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                >
                  <Label
                    position="center"
                    width={100}
                    content={
                      <CustomLabelTitle value1={"Glucose Metabolism Score"} />
                    }
                  ></Label>
                  <Label
                    position="center"
                    width={100}
                    content={
                      <CustomLabel
                        value1={sugarMetaPieState.value}
                        value2={sugarMetaPieState.name}
                      />
                    }
                  ></Label>
                  {scoreTemplate.map((entry, index) => (
                    <Cell
                      key={`cell-${index + 4}`}
                      fill={COLORS[(index + 2) % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <div className="radar__container">
              <div
                ref={sugarMetaRadarRef}
                hidden
                id="sugarMeta_radar"
                onClick={handleSugarMetaRadarOnClick}
              ></div>
              <RadarChart
                height={300}
                width={600}
                outerRadius="80%"
                data={sugarMetaRadarState}
              >
                <PolarGrid />
                <PolarAngleAxis dataKey="name" />
                <Radar
                  dataKey="x"
                  stroke="green"
                  fill="green"
                  fillOpacity={0.5}
                />
                <Tooltip content={<CustomTooltip />} />
              </RadarChart>
            </div>
            <div
              ref={sugarMetaRecomRef}
              hidden
              id="sugarMeta_recom"
              onClick={handleSugarMetaRecomOnClick}
            ></div>
          </div>
          <div className="recomm__container">
            <p className="score__word">
              <span>{sugarMetaRecomState.title}</span>
            </p>
            <br />
            <p className="word__definition">{sugarMetaRecomState.sub}</p>
            <ul className="word__ul">
              {sugarMetaRecomState.suggest.map((d) => (
                <li className="word__li">{d}</li>
              ))}
            </ul>
          </div>
          <div className="divider__container">
            <hr className="divider__style"></hr>
          </div>
          {/* Cholesterol Metabolism/Vascular Function scoring */}
          <div className="chart__container">
            <div className="chart__Box">
              <div
                ref={cholMetaPieRef}
                hidden
                id="cholMeta_pie"
                onClick={handleCholMetaPieOnClick}
              ></div>
              <PieChart width={300} height={300}>
                <Pie
                  data={scoreTemplate}
                  cx={150}
                  cy={190}
                  startAngle={180}
                  endAngle={cholMetaPieState.angle}
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                >
                  <Label
                    position="center"
                    width={100}
                    content={
                      <CustomLabelTitle
                        value1={"Cholesterol Metabolism Score"}
                      />
                    }
                  ></Label>
                  <Label
                    position="center"
                    width={100}
                    content={
                      <CustomLabel
                        value1={cholMetaPieState.value}
                        value2={cholMetaPieState.name}
                      />
                    }
                  ></Label>
                  {scoreTemplate.map((entry, index) => (
                    <Cell
                      key={`cell-${index + 4}`}
                      fill={COLORS[(index + 2) % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <div className="radar__container">
              <div
                ref={cholMetaRadarRef}
                hidden
                id="cholMeta_radar"
                onClick={handleCholMetaRadarOnClick}
              ></div>
              <RadarChart
                height={300}
                width={600}
                outerRadius="80%"
                data={cholMetaRadarState}
              >
                <PolarGrid />
                <PolarAngleAxis dataKey="name" />
                <Radar
                  dataKey="x"
                  stroke="green"
                  fill="green"
                  fillOpacity={0.5}
                />
                <Tooltip content={<CustomTooltip />} />
              </RadarChart>
            </div>
            <div
              ref={cholMetaRecomRef}
              hidden
              id="cholMeta_recom"
              onClick={handleCholMetaRecomOnClick}
            ></div>
          </div>
          <div className="recomm__container">
            <p className="score__word">
              <span>{cholMetaRecomState.title}</span>
            </p>
            <br />
            <p className="word__definition">{cholMetaRecomState.sub}</p>
            <ul className="word__ul">
              {cholMetaRecomState.suggest.map((d) => (
                <li className="word__li">{d}</li>
              ))}
            </ul>
          </div>
          <div className="divider__container">
            <hr className="divider__style"></hr>
          </div>
          {/* Oxygen Metabolism scoring */}
          <div className="chart__container">
            <div className="chart__Box">
              <div
                ref={o2MetaPieRef}
                hidden
                id="o2Meta_pie"
                onClick={handleO2MetaPieOnClick}
              ></div>
              <PieChart width={300} height={300}>
                <Pie
                  data={scoreTemplate}
                  cx={150}
                  cy={190}
                  startAngle={180}
                  endAngle={o2MetaPieState.angle}
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                >
                  <Label
                    position="center"
                    width={100}
                    content={
                      <CustomLabelTitle value1={"Oxygen Metabolism Score"} />
                    }
                  ></Label>
                  <Label
                    position="center"
                    width={100}
                    content={
                      <CustomLabel
                        value1={o2MetaPieState.value}
                        value2={o2MetaPieState.name}
                      />
                    }
                  ></Label>
                  {scoreTemplate.map((entry, index) => (
                    <Cell
                      key={`cell-${index + 4}`}
                      fill={COLORS[(index + 2) % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <div className="radar__container">
              <div
                ref={o2MetaRadarRef}
                hidden
                id="o2Meta_radar"
                onClick={handleO2MetaRadarOnClick}
              ></div>
              <RadarChart
                height={300}
                width={600}
                outerRadius="80%"
                data={o2MetaRadarState}
              >
                <PolarGrid />
                <PolarAngleAxis dataKey="name" />
                <Radar
                  dataKey="x"
                  stroke="green"
                  fill="green"
                  fillOpacity={0.5}
                />
                <Tooltip content={<CustomTooltip />} />
              </RadarChart>
            </div>
            <div
              ref={o2MetaRecomRef}
              hidden
              id="o2Meta_recom"
              onClick={handleO2MetaRecomOnClick}
            ></div>
          </div>
          <div className="recomm__container">
            <p className="score__word">
              <span>{o2MetaRecomState.title}</span>
            </p>
            <br />
            <p className="word__definition">{o2MetaRecomState.sub}</p>
            <ul className="word__ul">
              {o2MetaRecomState.suggest.map((d) => (
                <li className="word__li">{d}</li>
              ))}
            </ul>
          </div>
          <div className="divider__container">
            <hr className="divider__style"></hr>
          </div>
          {/* Risk to Stroke scoring */}
          <div className="chart__container">
            <div className="chart__Box">
              <div
                ref={strokePieRef}
                hidden
                id="stroke_pie"
                onClick={handleStrokePieOnClick}
              ></div>
              <PieChart width={300} height={300}>
                <Pie
                  data={scoreTemplate}
                  cx={150}
                  cy={190}
                  startAngle={180}
                  endAngle={strokePieState.angle}
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                >
                  <Label
                    position="center"
                    width={100}
                    content={<CustomLabelTitle value1={"Risk to Stroke"} />}
                  ></Label>
                  <Label
                    position="center"
                    width={100}
                    content={
                      <CustomLabel
                        value1={strokePieState.value}
                        value2={strokePieState.name}
                      />
                    }
                  ></Label>
                  {scoreTemplate.map((entry, index) => (
                    <Cell
                      key={`cell-${index + 4}`}
                      fill={COLORS[(index + 2) % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <div className="radar__container">
              <div
                ref={strokeRadarRef}
                hidden
                id="stroke_radar"
                onClick={handleStrokeRadarOnClick}
              ></div>
              <RadarChart
                height={300}
                width={600}
                outerRadius="80%"
                data={strokeRadarState}
              >
                <PolarGrid />
                <PolarAngleAxis dataKey="name" />
                <Radar
                  dataKey="x"
                  stroke="green"
                  fill="green"
                  fillOpacity={0.5}
                />
                <Tooltip content={<CustomTooltip />} />
              </RadarChart>
            </div>
            <div
              ref={strokeRecomRef}
              hidden
              id="stroke_recom"
              onClick={handleStrokeRecomOnClick}
            ></div>
          </div>
          <div className="recomm__container">
            <p className="score__word">
              <span>{strokeRecomState.title}</span>
            </p>
            <br />
            <p className="word__definition">{strokeRecomState.sub}</p>
            <ul className="word__ul">
              {strokeRecomState.suggest.map((d) => (
                <li className="word__li">{d}</li>
              ))}
            </ul>
          </div>
          <div className="divider__container">
            <hr className="divider__style"></hr>
          </div>
          {/* Healthy Eating Index (HEI) scoring */}
          <div className="chart__container">
            <div className="chart__Box">
              <div
                ref={heiPieRef}
                hidden
                id="hei_pie"
                onClick={handleHEIPieOnClick}
              ></div>
              <PieChart width={300} height={300}>
                <Pie
                  data={scoreTemplate}
                  cx={150}
                  cy={190}
                  startAngle={180}
                  endAngle={heiPieState.angle}
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                >
                  <Label
                    position="center"
                    width={100}
                    content={<CustomLabelTitle value1={"H.E.I"} />}
                  ></Label>
                  <Label
                    position="center"
                    width={100}
                    content={
                      <CustomLabel
                        value1={heiPieState.value}
                        value2={heiPieState.name}
                      />
                    }
                  ></Label>
                  {scoreTemplate.map((entry, index) => (
                    <Cell
                      key={`cell-${index + 4}`}
                      fill={COLORS[(index + 2) % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <div className="radar__container">
              <div
                ref={heiRadarRef}
                hidden
                id="hei_radar"
                onClick={handleHEIRadarOnClick}
              ></div>
              <RadarChart
                height={300}
                width={600}
                outerRadius="80%"
                data={heiRadarState}
              >
                <PolarGrid />
                <PolarAngleAxis dataKey="name" />
                <Radar
                  dataKey="x"
                  stroke="green"
                  fill="green"
                  fillOpacity={0.5}
                />
                <Tooltip content={<CustomTooltip />} />
              </RadarChart>
            </div>
            <div
              ref={heiRecomRef}
              hidden
              id="hei_recom"
              onClick={handleHEIRecomOnClick}
            ></div>
          </div>
          <div className="recomm__container">
            <p className="score__word">
              <span>{heiRecomState.title}</span>
            </p>
            <br />
            <p className="word__definition">{heiRecomState.sub}</p>
            <ul className="word__ul">
              {heiRecomState.suggest.map((d) => (
                <li className="word__li">{d}</li>
              ))}
            </ul>
          </div>
          <div className="divider__container">
            <hr className="divider__style"></hr>
          </div>
          {/* Vitamin Deficiency scoring */}
          <div className="chart__container">
            <div className="chart__Box">
              <div
                ref={vitaPieRef}
                hidden
                id="vita_pie"
                onClick={handleVitaPieOnClick}
              ></div>
              <PieChart width={300} height={300}>
                <Pie
                  data={scoreTemplate}
                  cx={150}
                  cy={190}
                  startAngle={180}
                  endAngle={vitaPieState.angle}
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                >
                  <Label
                    position="center"
                    width={100}
                    content={
                      <CustomLabelTitle value1={"Vitamin Deficiency Score"} />
                    }
                  ></Label>
                  <Label
                    position="center"
                    width={100}
                    content={
                      <CustomLabel
                        value1={vitaPieState.value}
                        value2={vitaPieState.name}
                      />
                    }
                  ></Label>
                  {scoreTemplate.map((entry, index) => (
                    <Cell
                      key={`cell-${index + 4}`}
                      fill={COLORS[(index + 2) % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <div className="radar__container">
              <div
                ref={vitaRadarRef}
                hidden
                id="vita_radar"
                onClick={handleVitaRadarOnClick}
              ></div>
              <RadarChart
                height={300}
                width={600}
                outerRadius="80%"
                data={vitaRadarState}
              >
                <PolarGrid />
                <PolarAngleAxis dataKey="name" />
                <Radar
                  dataKey="x"
                  stroke="green"
                  fill="green"
                  fillOpacity={0.5}
                />
                <Tooltip content={<CustomTooltip />} />
              </RadarChart>
            </div>
            <div
              ref={vitaRecomRef}
              hidden
              id="vita_recom"
              onClick={handleVitaRecomOnClick}
            ></div>
          </div>
          <div className="recomm__container">
            <p className="score__word">
              <span>{vitaRecomState.title}</span>
            </p>
            <br />
            <p className="word__definition">{vitaRecomState.sub}</p>
            <ul className="word__ul">
              {vitaRecomState.suggest.map((d) => (
                <li className="word__li">{d}</li>
              ))}
            </ul>
          </div>
          <div className="divider__container">
            <hr className="divider__style"></hr>
          </div>
        </div>
      ) : (
        <div className="flex flex-col mx-auto">
          {/* Stress/Anxiety scoring */}
          <div className="flex flex-col md:flex-row mx-auto">
            <div className="md:w-1/2">
              <div
                ref={stressPieRef}
                hidden
                id="stress_pie"
                onClick={handleStressPieOnClick}
              ></div>

              <PieChart width={300} height={300} className="mx-auto">
                <Pie
                  data={scoreTemplate}
                  cx={150}
                  cy={190}
                  startAngle={180}
                  endAngle={stresPieState.angle}
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                  className="mx-auto"
                >
                  <Label
                    position="center"
                    width={100}
                    content={<CustomLabelTitle value1={"Stress Score"} />}
                  ></Label>
                  <Label
                    position="center"
                    width={100}
                    content={
                      <CustomLabel
                        value1={stresPieState.value}
                        value2={stresPieState.name}
                      />
                    }
                  ></Label>
                  {scoreTemplate.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <div className="radar__container">
              <div
                ref={stressRadarRef}
                hidden
                id="stress_radar"
                onClick={handleStressRadarOnClick}
              ></div>
              {/* <ResponsiveContainer> */}
              <RadarChart
                height={300}
                width={600}
                cx="50%"
                cy="50%"
                outerRadius="80%"
                data={stresRadarState}
                // className="mx-auto"
              >
                <PolarGrid />
                <PolarAngleAxis dataKey="name" />
                <Radar
                  dataKey="x"
                  stroke="green"
                  fill="green"
                  fillOpacity={0.5}
                />
                <Tooltip content={<CustomTooltip />} />
              </RadarChart>
              {/* </ResponsiveContainer> */}
            </div>
            <div
              ref={stressRecomRef}
              hidden
              id="stress_recom"
              onClick={handleStressRecomOnClick}
            ></div>
          </div>
          <div className="recomm__container1 w-3/4 md:w-full border-2 rounded-xl shadow-lg shadow-gray-500 text-left mx-8 p-4">
            <p className="score__word text-2xl font-black text-left">
              <span>{stressRecomState.title}</span>
            </p>
            <br />
            <p className="word__definition w-3/4 md:w-full">
              {stressRecomState.sub}
            </p>
            <ul className="word__ul md:ml-12 ml-5">
              {stressRecomState.suggest.map((d) => (
                <li className="word__li">{d}</li>
              ))}
            </ul>
          </div>
          <div className="divider__container1 w-full ">
            <hr className="divider__style1 w-full h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700"></hr>
          </div>
          {/* Fatigue/Sleep scoring */}
          <div className="flex flex-col md:flex-row mx-auto">
            <div className="md:w-1/2">
              <div
                ref={fatiguePieRef}
                hidden
                id="fatigue_pie"
                onClick={handleFatiguePieOnClick}
              ></div>
              <PieChart width={300} height={300} className="mx-auto">
                <Pie
                  data={scoreTemplate}
                  cx={150}
                  cy={190}
                  startAngle={180}
                  endAngle={fatiguePieState.angle}
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                  className="mx-auto"
                >
                  <Label
                    position="center"
                    width={100}
                    content={<CustomLabelTitle value1={"Fatigue Score"} />}
                  ></Label>
                  <Label
                    position="center"
                    width={100}
                    content={
                      <CustomLabel
                        value1={fatiguePieState.value}
                        value2={fatiguePieState.name}
                      />
                    }
                  ></Label>
                  {scoreTemplate.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[(index + 4) % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <div className="radar__container">
              <div
                ref={fatigueRadarRef}
                hidden
                id="fatigue_radar"
                onClick={handleFatigueRadarOnClick}
              ></div>
              <RadarChart
                height={300}
                width={600}
                outerRadius="80%"
                data={fatigueRadarState}
              >
                <PolarGrid />
                <PolarAngleAxis dataKey="name" />
                <Radar
                  dataKey="x"
                  stroke="green"
                  fill="green"
                  fillOpacity={0.5}
                />
                <Tooltip content={<CustomTooltip />} />
              </RadarChart>
            </div>
            <div
              ref={fatigueRecomRef}
              hidden
              id="fatigue_recom"
              onClick={handleFatigueRecomOnClick}
            ></div>
          </div>
          <div className="recomm__container w-3/4 md:w-full border-2 rounded-xl shadow-lg shadow-gray-500 text-left mx-8 p-4">
            <p className="score__word text-2xl font-black text-left">
              <span>{fatigueRecomState.title}</span>
            </p>
            <br />
            <p className="word__definition">{fatigueRecomState.sub}</p>
            <ul className="word__ul md:ml-12 ml-5">
              {fatigueRecomState.suggest.map((d) => (
                <li className="word__li">{d}</li>
              ))}
            </ul>
          </div>
          <div className="divider__container1 w-full ">
            <hr className="divider__style1 w-full h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700"></hr>
          </div>
          {/* Yin/ scoring */}
          <div className="flex flex-col md:flex-row mx-auto">
            <div className="md:w-1/2">
              <div
                ref={yinPieRef}
                hidden
                id="yin_pie"
                onClick={handleYinPieOnClick}
              ></div>
              <PieChart width={300} height={300} className="mx-auto">
                <Pie
                  data={scoreTemplate}
                  cx={150}
                  cy={190}
                  startAngle={180}
                  endAngle={yinPieState.angle}
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                >
                  <Label
                    position="center"
                    width={100}
                    content={<CustomLabelTitle value1={"Yin Score"} />}
                  ></Label>
                  <Label
                    position="center"
                    width={100}
                    content={
                      <CustomLabel
                        value1={yinPieState.value}
                        value2={yinPieState.name}
                      />
                    }
                  ></Label>
                  {scoreTemplate.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[(index + 2) % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <div className="radar__container">
              <div
                ref={yinRadarRef}
                hidden
                id="yin_radar"
                onClick={handleYinRadarOnClick}
              ></div>
              <RadarChart
                height={300}
                width={600}
                outerRadius="80%"
                data={yinRadarState}
              >
                <PolarGrid />
                <PolarAngleAxis dataKey="name" />
                <Radar
                  dataKey="x"
                  stroke="green"
                  fill="green"
                  fillOpacity={0.5}
                />
                <Tooltip content={<CustomTooltip />} />
              </RadarChart>
            </div>
            <div
              ref={yinRecomRef}
              hidden
              id="yin_recom"
              onClick={handleYinRecomOnClick}
            ></div>
          </div>
          <div className="recomm__container w-3/4 md:w-full border-2 rounded-xl shadow-lg shadow-gray-500 text-left mx-8 p-4">
            <p className="score__word text-2xl font-black text-left">
              <span>{yinRecomState.title}</span>
            </p>
            <br />
            <p className="word__definition w-3/4 md:w-full">
              {yinRecomState.sub}
            </p>
            <ul className="word__ul md:ml-12 ml-5">
              {yinRecomState.suggest.map((d) => (
                <li className="word__li">{d}</li>
              ))}
            </ul>
          </div>
          <div className="divider__containern w-full">
            <hr className="divider__stylen w-full h-1  mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700"></hr>
          </div>
          {/* Yin/ scoring */}
          <div className="flex flex-col md:flex-row mx-auto">
            <div className="md:w-1/2">
              <div
                ref={yangPieRef}
                hidden
                id="yang_pie"
                onClick={handleYangPieOnClick}
              ></div>
              <PieChart width={300} height={300} className="mx-auto">
                <Pie
                  data={scoreTemplate}
                  cx={150}
                  cy={190}
                  startAngle={180}
                  endAngle={yangPieState.angle}
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                >
                  <Label
                    position="center"
                    width={100}
                    content={<CustomLabelTitle value1={"Yang Score"} />}
                  ></Label>
                  <Label
                    position="center"
                    width={100}
                    content={
                      <CustomLabel
                        value1={yangPieState.value}
                        value2={yangPieState.name}
                      />
                    }
                  ></Label>
                  {scoreTemplate.map((entry, index) => (
                    <Cell
                      key={`cell-${index + 4}`}
                      fill={COLORS[(index + 2) % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <div className="radar__container">
              <div
                ref={yangRadarRef}
                hidden
                id="yang_radar"
                onClick={handleYangRadarOnClick}
              ></div>
              <RadarChart
                height={300}
                width={600}
                outerRadius="80%"
                data={yangRadarState}
              >
                <PolarGrid />
                <PolarAngleAxis dataKey="name" />
                <Radar
                  dataKey="x"
                  stroke="green"
                  fill="green"
                  fillOpacity={0.5}
                />
                <Tooltip content={<CustomTooltip />} />
              </RadarChart>
            </div>
            <div
              ref={yangRecomRef}
              hidden
              id="yang_recom"
              onClick={handleYangRecomOnClick}
            ></div>
          </div>
          <div className="recomm__containerd  w-3/4 md:w-full border-2 rounded-xl shadow-lg shadow-gray-500 text-left mx-8 p-4">
            <p className="score__word text-2xl font-black text-left">
              <span>{yangRecomState.title}</span>
            </p>
            <br />
            <p className="word__definition w-3/4 md:w-full  ">
              {yangRecomState.sub}
            </p>
            <ul className="word__ul md:ml-12 ml-5">
              {yangRecomState.suggest.map((d) => (
                <li className="word__li">{d}</li>
              ))}
            </ul>
          </div>
          <div className="divider__containerr w-full">
            <hr className="divider__stylew w-full h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700"></hr>
          </div>
          {/* Sensitivity scoring */}
          <div className="chart__containere flex flex-col md:flex-row mx-auto">
            <div className="chart__Boxc md:w-1/2 ">
              <div
                ref={sensePieRef}
                hidden
                id="sense_pie"
                onClick={handleSensePieOnClick}
              ></div>
              <PieChart width={300} height={300} className="mx-auto">
                <Pie
                  data={scoreTemplate}
                  cx={150}
                  cy={190}
                  startAngle={180}
                  endAngle={sensePieState.angle}
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                >
                  <Label
                    position="center"
                    width={100}
                    content={<CustomLabelTitle value1={"Sensitivity Score"} />}
                  ></Label>
                  <Label
                    position="center"
                    width={100}
                    content={
                      <CustomLabel
                        value1={sensePieState.value}
                        value2={sensePieState.name}
                      />
                    }
                  ></Label>
                  {scoreTemplate.map((entry, index) => (
                    <Cell
                      key={`cell-${index + 4}`}
                      fill={COLORS[(index + 2) % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <div className="radar__container">
              <div
                ref={senseRadarRef}
                hidden
                id="sense_radar"
                onClick={handleSenseRadarOnClick}
              ></div>
              <RadarChart
                height={300}
                width={600}
                outerRadius="80%"
                data={senseRadarState}
              >
                <PolarGrid />
                <PolarAngleAxis dataKey="name" />
                <Radar
                  dataKey="x"
                  stroke="green"
                  fill="green"
                  fillOpacity={0.5}
                />
                <Tooltip content={<CustomTooltip />} />
              </RadarChart>
            </div>
            <div
              ref={senseRecomRef}
              hidden
              id="sense_recom"
              onClick={handleSenseRecomOnClick}
            ></div>
          </div>
          <div className="recomm__container w-3/4 md:w-full border-2 rounded-xl shadow-lg shadow-gray-500 text-left mx-8 p-4">
            <p className="score__word text-2xl font-black text-left">
              <span>{senseRecomState.title}</span>
            </p>
            <br />
            <p className="word__definition">{senseRecomState.sub}</p>
            <ul className="word__ul md:ml-12 ml-5">
              {senseRecomState.suggest.map((d) => (
                <li className="word__li">{d}</li>
              ))}
            </ul>
          </div>
          <div className="divider__container1 w-full">
            <hr className="divider__styleb w-full h-1 mx-auto my-2 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700"></hr>
          </div>
          {/* Sugar Metabolism scoring */}
          <div className="flex flex-col md:flex-row mx-auto">
            <div className="md:w-1/2">
              <div
                ref={sugarMetaPieRef}
                hidden
                id="sugarMeta_pie"
                onClick={handleSugarMetaPieOnClick}
              ></div>
              <PieChart width={300} height={300} className="mx-auto">
                <Pie
                  data={scoreTemplate}
                  cx={150}
                  cy={190}
                  startAngle={180}
                  endAngle={sugarMetaPieState.angle}
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                >
                  <Label
                    position="center"
                    width={100}
                    content={
                      <CustomLabelTitle value1={"Glucose Metabolism Score"} />
                    }
                  ></Label>
                  <Label
                    position="center"
                    width={100}
                    content={
                      <CustomLabel
                        value1={sugarMetaPieState.value}
                        value2={sugarMetaPieState.name}
                      />
                    }
                  ></Label>
                  {scoreTemplate.map((entry, index) => (
                    <Cell
                      key={`cell-${index + 4}`}
                      fill={COLORS[(index + 2) % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <div className="radar__container">
              <div
                ref={sugarMetaRadarRef}
                hidden
                id="sugarMeta_radar"
                onClick={handleSugarMetaRadarOnClick}
              ></div>
              <RadarChart
                height={300}
                width={600}
                outerRadius="80%"
                data={sugarMetaRadarState}
              >
                <PolarGrid />
                <PolarAngleAxis dataKey="name" />
                <Radar
                  dataKey="x"
                  stroke="green"
                  fill="green"
                  fillOpacity={0.5}
                />
                <Tooltip content={<CustomTooltip />} />
              </RadarChart>
            </div>
            <div
              ref={sugarMetaRecomRef}
              hidden
              id="sugarMeta_recom"
              onClick={handleSugarMetaRecomOnClick}
            ></div>
          </div>
          <div className="recomm__container w-3/4 md:w-full border-2 rounded-xl shadow-lg shadow-gray-500 text-left mx-8 p-4">
            <p className="score__word text-2xl font-black text-left">
              <span>{sugarMetaRecomState.title}</span>
            </p>
            <br />
            <p className="word__definition">{sugarMetaRecomState.sub}</p>
            <ul className="word__ul md:ml-12 ml-5">
              {sugarMetaRecomState.suggest.map((d) => (
                <li className="word__li">{d}</li>
              ))}
            </ul>
          </div>
          <div className="divider__container">
            <hr className="divider__style"></hr>
          </div>
          {/* Cholesterol Metabolism/Vascular Function scoring */}
          <div className="chart__container flex flex-col md:flex-row mx-auto">
            <div className="chart__Box md:w-1/2">
              <div
                ref={cholMetaPieRef}
                hidden
                id="cholMeta_pie"
                onClick={handleCholMetaPieOnClick}
              ></div>
              <PieChart width={300} height={300} className="mx-auto">
                <Pie
                  data={scoreTemplate}
                  cx={150}
                  cy={190}
                  startAngle={180}
                  endAngle={cholMetaPieState.angle}
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                >
                  <Label
                    position="center"
                    width={100}
                    content={
                      <CustomLabelTitle
                        value1={"Cholesterol Metabolism Score"}
                      />
                    }
                  ></Label>
                  <Label
                    position="center"
                    width={100}
                    content={
                      <CustomLabel
                        value1={cholMetaPieState.value}
                        value2={cholMetaPieState.name}
                      />
                    }
                  ></Label>
                  {scoreTemplate.map((entry, index) => (
                    <Cell
                      key={`cell-${index + 4}`}
                      fill={COLORS[(index + 2) % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <div className="radar__container">
              <div
                ref={cholMetaRadarRef}
                hidden
                id="cholMeta_radar"
                onClick={handleCholMetaRadarOnClick}
              ></div>
              <RadarChart
                height={300}
                width={600}
                outerRadius="80%"
                data={cholMetaRadarState}
              >
                <PolarGrid />
                <PolarAngleAxis dataKey="name" />
                <Radar
                  dataKey="x"
                  stroke="green"
                  fill="green"
                  fillOpacity={0.5}
                />
                <Tooltip content={<CustomTooltip />} />
              </RadarChart>
            </div>
            <div
              ref={cholMetaRecomRef}
              hidden
              id="cholMeta_recom"
              onClick={handleCholMetaRecomOnClick}
            ></div>
          </div>
          <div className="recomm__container w-3/4 md:w-full border-2 rounded-xl shadow-lg shadow-gray-500 text-left mx-8 p-4">
            <p className="score__word text-2xl font-black text-left">
              <span>{cholMetaRecomState.title}</span>
            </p>
            <br />
            <p className="word__definition">{cholMetaRecomState.sub}</p>
            <ul className="word__ul md:ml-12 ml-5">
              {cholMetaRecomState.suggest.map((d) => (
                <li className="word__li">{d}</li>
              ))}
            </ul>
          </div>
          <div className="divider__container1 w-full">
            <hr className="divider__style w-full h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700"></hr>
          </div>
          {/* Oxygen Metabolism scoring */}
          <div className="chart__container flex flex-col md:flex-row mx-auto">
            <div className="chart__Box md:w-1/2">
              <div
                ref={o2MetaPieRef}
                hidden
                id="o2Meta_pie"
                onClick={handleO2MetaPieOnClick}
              ></div>
              <PieChart width={300} height={300} className="mx-auto">
                <Pie
                  data={scoreTemplate}
                  cx={150}
                  cy={190}
                  startAngle={180}
                  endAngle={o2MetaPieState.angle}
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                >
                  <Label
                    position="center"
                    width={100}
                    content={
                      <CustomLabelTitle value1={"Oxygen Metabolism Score"} />
                    }
                  ></Label>
                  <Label
                    position="center"
                    width={100}
                    content={
                      <CustomLabel
                        value1={o2MetaPieState.value}
                        value2={o2MetaPieState.name}
                      />
                    }
                  ></Label>
                  {scoreTemplate.map((entry, index) => (
                    <Cell
                      key={`cell-${index + 4}`}
                      fill={COLORS[(index + 2) % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <div className="radar__container">
              <div
                ref={o2MetaRadarRef}
                hidden
                id="o2Meta_radar"
                onClick={handleO2MetaRadarOnClick}
              ></div>
              <RadarChart
                height={300}
                width={600}
                outerRadius="80%"
                data={o2MetaRadarState}
              >
                <PolarGrid />
                <PolarAngleAxis dataKey="name" />
                <Radar
                  dataKey="x"
                  stroke="green"
                  fill="green"
                  fillOpacity={0.5}
                />
                <Tooltip content={<CustomTooltip />} />
              </RadarChart>
            </div>
            <div
              ref={o2MetaRecomRef}
              hidden
              id="o2Meta_recom"
              onClick={handleO2MetaRecomOnClick}
            ></div>
          </div>
          <div className="recomm__container w-3/4 md:w-full border-2 rounded-xl shadow-lg shadow-gray-500 text-left mx-8 p-4">
            <p className="score__word text-2xl font-black text-left">
              <span>{o2MetaRecomState.title}</span>
            </p>
            <br />
            <p className="word__definition">{o2MetaRecomState.sub}</p>
            <ul className="word__ul md:ml-12 ml-5">
              {o2MetaRecomState.suggest.map((d) => (
                <li className="word__li">{d}</li>
              ))}
            </ul>
          </div>
          <div className="divider__container1 w-full">
            <hr className="divider__style1 w-full h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700"></hr>
          </div>
          {/* Risk to Stroke scoring */}
          <div className="chart__container flex flex-col md:flex-row mx-auto">
            <div className="chart__Box md:w-1/2">
              <div
                ref={strokePieRef}
                hidden
                id="stroke_pie"
                onClick={handleStrokePieOnClick}
              ></div>
              <PieChart width={300} height={300} className="mx-auto">
                <Pie
                  data={scoreTemplate}
                  cx={150}
                  cy={190}
                  startAngle={180}
                  endAngle={strokePieState.angle}
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                >
                  <Label
                    position="center"
                    width={100}
                    content={<CustomLabelTitle value1={"Risk to Stroke"} />}
                  ></Label>
                  <Label
                    position="center"
                    width={100}
                    content={
                      <CustomLabel
                        value1={strokePieState.value}
                        value2={strokePieState.name}
                      />
                    }
                  ></Label>
                  {scoreTemplate.map((entry, index) => (
                    <Cell
                      key={`cell-${index + 4}`}
                      fill={COLORS[(index + 2) % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <div className="radar__container">
              <div
                ref={strokeRadarRef}
                hidden
                id="stroke_radar"
                onClick={handleStrokeRadarOnClick}
              ></div>
              <RadarChart
                height={300}
                width={600}
                outerRadius="80%"
                data={strokeRadarState}
              >
                <PolarGrid />
                <PolarAngleAxis dataKey="name" />
                <Radar
                  dataKey="x"
                  stroke="green"
                  fill="green"
                  fillOpacity={0.5}
                />
                <Tooltip content={<CustomTooltip />} />
              </RadarChart>
            </div>
            <div
              ref={strokeRecomRef}
              hidden
              id="stroke_recom"
              onClick={handleStrokeRecomOnClick}
            ></div>
          </div>
          <div className="recomm__container w-3/4 md:w-full border-2 rounded-xl shadow-lg shadow-gray-500 text-left mx-8 p-4">
            <p className="score__word font-black text-left">
              <span>{strokeRecomState.title}</span>
            </p>
            <br />
            <p className="word__definition">{strokeRecomState.sub}</p>
            <ul className="word__ul md:ml-12 ml-5">
              {strokeRecomState.suggest.map((d) => (
                <li className="word__li">{d}</li>
              ))}
            </ul>
          </div>
          <div className="divider__container1 w-full ">
            <hr className="divider__style1 w-full h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700"></hr>
          </div>
          {/* Healthy Eating Index (HEI) scoring */}
          <div className="chart__container flex flex-col md:flex-row mx-auto">
            <div className="chart__Box md:w-1/2">
              <div
                ref={heiPieRef}
                hidden
                id="hei_pie"
                onClick={handleHEIPieOnClick}
              ></div>
              <PieChart width={300} height={300} className="mx-auto">
                <Pie
                  data={scoreTemplate}
                  cx={150}
                  cy={190}
                  startAngle={180}
                  endAngle={heiPieState.angle}
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                >
                  <Label
                    position="center"
                    width={100}
                    content={<CustomLabelTitle value1={"H.E.I"} />}
                  ></Label>
                  <Label
                    position="center"
                    width={100}
                    content={
                      <CustomLabel
                        value1={heiPieState.value}
                        value2={heiPieState.name}
                      />
                    }
                  ></Label>
                  {scoreTemplate.map((entry, index) => (
                    <Cell
                      key={`cell-${index + 4}`}
                      fill={COLORS[(index + 2) % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <div className="radar__container">
              <div
                ref={heiRadarRef}
                hidden
                id="hei_radar"
                onClick={handleHEIRadarOnClick}
              ></div>
              <RadarChart
                height={300}
                width={600}
                outerRadius="80%"
                data={heiRadarState}
              >
                <PolarGrid />
                <PolarAngleAxis dataKey="name" />
                <Radar
                  dataKey="x"
                  stroke="green"
                  fill="green"
                  fillOpacity={0.5}
                />
                <Tooltip content={<CustomTooltip />} />
              </RadarChart>
            </div>
            <div
              ref={heiRecomRef}
              hidden
              id="hei_recom"
              onClick={handleHEIRecomOnClick}
            ></div>
          </div>
          <div className="recomm__container w-3/4 md:w-full border-2 rounded-xl shadow-lg shadow-gray-500 text-left mx-8 p-4">
            <p className="score__word text-2xl font-black text-left">
              <span>{heiRecomState.title}</span>
            </p>
            <br />
            <p className="word__definition">{heiRecomState.sub}</p>
            <ul className="word__ul md:ml-12 ml-5">
              {heiRecomState.suggest.map((d) => (
                <li className="word__li">{d}</li>
              ))}
            </ul>
          </div>
          <div className="divider__container1 w-full ">
            <hr className="divider__style1 w-full h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700"></hr>
          </div>
          {/* Vitamin Deficiency scoring */}
          <div className="chart__container flex flex-col md:flex-row mx-auto">
            <div className="chart__Box md:w-1/2">
              <div
                ref={vitaPieRef}
                hidden
                id="vita_pie"
                onClick={handleVitaPieOnClick}
              ></div>
              <PieChart width={300} height={300} className="mx-auto">
                <Pie
                  data={scoreTemplate}
                  cx={150}
                  cy={190}
                  startAngle={180}
                  endAngle={vitaPieState.angle}
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                >
                  <Label
                    position="center"
                    width={100}
                    content={
                      <CustomLabelTitle value1={"Vitamin Deficiency Score"} />
                    }
                  ></Label>
                  <Label
                    position="center"
                    width={100}
                    content={
                      <CustomLabel
                        value1={vitaPieState.value}
                        value2={vitaPieState.name}
                      />
                    }
                  ></Label>
                  {scoreTemplate.map((entry, index) => (
                    <Cell
                      key={`cell-${index + 4}`}
                      fill={COLORS[(index + 2) % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <div className="radar__container">
              <div
                ref={vitaRadarRef}
                hidden
                id="vita_radar"
                onClick={handleVitaRadarOnClick}
              ></div>
              <RadarChart
                height={300}
                width={600}
                outerRadius="80%"
                data={vitaRadarState}
              >
                <PolarGrid />
                <PolarAngleAxis dataKey="name" />
                <Radar
                  dataKey="x"
                  stroke="green"
                  fill="green"
                  fillOpacity={0.5}
                />
                <Tooltip content={<CustomTooltip />} />
              </RadarChart>
            </div>
            <div
              ref={vitaRecomRef}
              hidden
              id="vita_recom"
              onClick={handleVitaRecomOnClick}
            ></div>
          </div>
          <div className="recomm__container w-3/4 md:w-full border-2 rounded-xl shadow-lg shadow-gray-500 text-left mx-8 p-4">
            <p className="score__word text-2xl font-black text-left">
              <span>{vitaRecomState.title}</span>
            </p>
            <br />
            <p className="word__definition">{vitaRecomState.sub}</p>
            <ul className="word__ul md:ml-12 ml-5">
              {vitaRecomState.suggest.map((d) => (
                <li className="word__li">{d}</li>
              ))}
            </ul>
          </div>
          <div className="divider__container1 w-full ">
            <hr className="divider__style1 w-full h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700"></hr>
          </div>
        </div>
      )}
    </div>
  );
}
export default StressChart;
