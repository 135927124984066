import React from "react";
import "../Vitals.css";
import StressChart from "./StressChart";
export default function VitalsV1() {
  return (
    <div className="vitals__body">
      <div className="scan__vitalBox">
        <div className="scan__Box">
          <div className="scanLabel__box">
            <label htmlFor="heart_rate" className="scan__label">
              Heart Rate
            </label>
          </div>
          <input id="hr_index" hidden></input>
          <div className="scanInput__box">
            <input
              type="text"
              readOnly
              id="heart_rate"
              className="scan__input"
            ></input>
          </div>
          <div className="plusmin__box">
            <p>
              <span className="plusMin">+/- 5 b.p.m</span>
            </p>
          </div>
        </div>
        <div className="scan__Box">
          <div className="scanLabel__box">
            <label htmlFor="resp_rate" className="scan__label">
              Breathing Rate
            </label>
          </div>
          <input id="br_index" hidden></input>
          <div className="scanInput__box">
            <input
              type="text"
              readOnly
              id="resp_rate"
              className="scan__input"
            ></input>
          </div>
          <div className="plusmin__box">
            <p>
              <span className="plusMin">+/- 5 b.p.m</span>
            </p>
          </div>
        </div>
        <div className="scan__Box">
          <div className="scanLabel__box">
            <label htmlFor="spo2" className="scan__label">
              Spo2 Rate
            </label>
          </div>
          <input id="spo2_index" hidden></input>
          <div className="scanInput__box">
            <input
              type="text"
              readOnly
              id="spo2"
              className="scan__input"
            ></input>
          </div>
          <div className="plusmin__box">
            <p>
              <span className="plusMin">+/- 2 %</span>
            </p>
          </div>
        </div>
      </div>
      <div className="scan__vitalBox">
        <div className="scan__Box">
          <div className="scanLabel__box">
            <label htmlFor="blood_pressure" className="scan__label">
              Blood Pressure
            </label>
          </div>
          <input id="bp_index" hidden></input>
          <div className="scanInput__box">
            <input
              type="text"
              readOnly
              id="blood_pressure"
              className="scan__input"
            ></input>
          </div>
          <div className="plusmin__box">
            <p>
              <span className="plusMin">+/- 15 mmHg</span>
            </p>
          </div>
        </div>
        <div className="scan__Box">
          <div className="scanLabel__box">
            <label htmlFor="bs_rate" className="scan__label">
              Blood Sugar
            </label>
          </div>
          <input id="bs_index" hidden></input>
          <div className="scanInput__box">
            <input
              type="text"
              readOnly
              id="bs_rate"
              className="scan__input"
            ></input>
          </div>
          <div className="plusmin__box">
            <p>
              <span className="plusMin">+/- 1 mmol/L</span>
            </p>
          </div>
        </div>
        <div className="scan__Box">
          <div className="scanLabel__box">
            <label htmlFor="chol_rate" className="scan__label">
              Blood Cholesterol
            </label>
          </div>
          <input id="bc_index" hidden></input>
          <div className="scanInput__box">
            <input
              type="text"
              readOnly
              id="chol_rate"
              className="scan__input"
            ></input>
          </div>
          <div className="plusmin__box">
            <p>
              <span className="plusMin">+/- 18 mg/dL</span>
            </p>
          </div>
        </div>
      </div>
      <div className="divider__container">
        <hr className="divider__style"></hr>
      </div>
      <div>
        <input hidden id="diag_value"></input>
        <input hidden id="diagnosis"></input>
        <input hidden id="check_hr"></input>
        <input hidden id="check_br"></input>
        <input hidden id="check_spo2"></input>
        <input hidden id="check_bp"></input>
        <input hidden id="check_bs"></input>
        <input hidden id="check_bc"></input>
      </div>
      <StressChart />
    </div>
  );
}
