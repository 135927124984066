import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
} from "react-router-dom";
import Scan from "./components/Scan";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import ScanV1 from "./components/ScanV1";
import Subscription from "./components/Subscription";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
function RequireAuth({ children }) {
  return localStorage.getItem("status") == "true" ? (
    children
  ) : (
    <Navigate to="/" replace />
  );
}
function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/scan"
          element={
            <RequireAuth>
              <Scan />
            </RequireAuth>
          }
        />
        <Route
          path="/subscription"
          element={
            <RequireAuth>
              <Subscription />
            </RequireAuth>
          }
        />
        <Route path="/" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/vi" element={<ScanV1 />} />
      </Routes>
    </Router>
  );
}
export default App;
