import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logout from "./Logout";
import url from "./url";

let config = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("token"),
  },
};

export default function MyModal() {
  let [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    checkScan();
  }, []);

  const checkScan = () => {
    axios
      .get(url + "sdk/scans", config)
      .then((r) => {
        let res = r.data.result;

        if (res.length == 0) {
          setIsOpen(true);
          return;
        }
        if (res[0]["remaining_scans"] < 1) {
          setIsOpen(true);
          return;
        }
      })
      .catch((e) => {
        console.log(e.response.status);
        console.log(e.response.status);
        // alert(e.response.status);
        if (e.response.status == 401) {
          let fails = localStorage.getItem("fails");

          if (fails == null) {
            localStorage.setItem("fails", 0);
            // alert("its null");
            window.location.reload();
          } else {
            // alert("not null");
            navigate("/");
          }
          // alert(fails);
          // sessionStorage.setItem('fails',)
          // window.location.reload();
        }
      });
  };
  function closeModal() {
    // setIsOpen(false);
  }

  function openModal() {
    // setIsOpen(true);
  }

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center">
        {/* <button
          type="button"
          onClick={openModal}
          className="rounded-md bg-black bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
        >
          Open dialog
        </button> */}
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {/* Please buy some scans */}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm p-4 font-serif font-black">
                      Our wellness tracking platform can help you improve your
                      overall wellness, and to access all of our features, we
                      require a small payment of $1 per scan. This will allow
                      you to add and track as many scans as you need. Please add
                      the desired number of scans to your cart and proceed to
                      checkout.
                    </p>
                    <p className="pl-4  mt-2">Thank you!</p>
                  </div>

                  <div className="mt-4">
                    <Link
                      type="button"
                      to="/subscription"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Go to subscription
                    </Link>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
